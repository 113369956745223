.table {

	margin-top: 0 !important;

	.label {
		width: 10rem;

		.subtext {
			font-weight: normal !important;
		}
	}

	&:global(.ui.definition):not(:global(.unstackable)) {
		@media screen and (max-width: 767px) {
			tr td:first-child {

				/**
				 * Otherwise the definition background colour 
				 * is visible as a banner, but with the table 
				 * background above and below due to padding
				 *
				 * The breakpoint above must match the Semantic 
				 * breakpoint where the table becomes collapsed.
				 */
				background: inherit !important;

			}
		}
	}

}
