$ROOT_PB: 1rem;

.root {
	background: #fff;
	margin-bottom: -$ROOT_PB;
	padding-bottom: $ROOT_PB;

	:global(.field) {
		margin: 0 !important;
	}
}
