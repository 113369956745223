.root {

	/** Remove the margin from any item that follows a segment header */
	:global(.ui.header) + * {
		margin: 0;
	}

}

.table {

	/** Remove excess spacing between multi-line text lines */
	p {
		margin: 0;
	}

}
