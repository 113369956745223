.root {
	
	display: flex;
	flex-direction: column;
	gap: 1rem;
	overflow-y: auto;
	max-height: calc(100vh - 4rem);
	z-index: 10000;
	
	position: fixed;
	bottom: 2rem;
	left: 50%;
	padding: 0 1rem;
	width: 100%;
	max-width: 32rem;
	transform: translateX(-50%);

	:global(.ui.message) {
		flex-shrink: 0;
		margin: 0;
	}

}
