.button {
	min-width: 6.5rem !important;
}

.dropdown {

	flex-grow: 0 !important;
	
	:global(.menu) {
		max-width: unset;
	}

}
