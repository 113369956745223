$APP_BAR_HEIGHT: 6rem;

.root {
	align-items: center;
	align-self: flex-start;
	background: #fff;
	display: flex;
	height: $APP_BAR_HEIGHT;
	justify-content: space-between;
	padding: 0 1rem;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 100;

	h1, p {
		margin: 0;
	}

	.app {
		align-items: center;
		display: flex;
		gap: 1.5rem;
	}

	.icon {
		width: calc($APP_BAR_HEIGHT * 0.8);
	}

	.icon__button {
		&:global(.ui) {
			padding: 0.2rem 0.8rem;
		}
	}

	:global(.menu) {
		margin: 0;
	}
}

.spacer {
	height: calc($APP_BAR_HEIGHT + 4rem);

	@media all and (max-width: 990px) {
		height: calc($APP_BAR_HEIGHT + 2rem);
	}
}
