.root {
	&:global(.ui) {
		box-shadow: none;
		margin: 0;
	}
}

.header {
	&:global(.ui) {
		margin: 0;
	}
}
