.root {

	align-self: center;
	justify-self: center;
	max-width: 28rem;
	margin: 0 auto;
	padding: 3rem 1rem 1rem 1rem;

	.container {
		margin: unset !important;
		width: 100% !important;
	}

	.form {
		margin: 0 auto;
		width: 100% !important;
	}

	.icon {
		display: block;
		height: 6rem;
		margin: 0 auto;
	}

	.label {
		width: 100% !important;
	}

	.messages, .messages .message, .totpMessage {
		margin: 0 !important;
		width: 100% !important;
	}

	.segment {
		margin: 0 auto;
		width: 100% !important;
	}

	.submit {
		width: 8rem;
	}

	:global(.field) {
		margin-bottom: 0 !important;
		width: 100% !important;
	}

}
