.contentContainer {
	flex-basis: 100%;
}

.personName {
	font-size: 1.4em !important;
}

.removeButton {
	justify-self: flex-end;
}
