$BP_MOBILE: 1200px;

.root {
	align-items: center;
	display: flex;

	>* {
		@media all and (min-width: calc($BP_MOBILE + 1px)) {
			&.mobile {
				display: none;
			}
		}
		@media all and (max-width: $BP_MOBILE) {
			&:not(.mobile) {
				display: none !important;
			}
		}
	}

}
