.root {
	&:global(.mini) :global(.icon) {
		font-size: 1.5em !important;
		margin-right: 0 !important;
	}
}

.container {
	background: #fff;
	margin-bottom: -1rem;
	padding-bottom: 1rem;
	position: sticky;
	top: 6rem;
	z-index: 100;
}
