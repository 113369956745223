@mixin form {
	:global(.field) {

		font-size: 1.1rem !important;
		margin: 0 !important;

		input, textarea, label, div:global(.menu) div:global(.item) {
			font-size: inherit !important;
		}

		input:not(:global(.search)), textarea, :global(.radio) {
			margin-top: 0.5em !important;
		}

		:global(.checkbox), :global(.radio) {
			& {
				font-size: inherit !important;
				margin-bottom: 0.5em;
			}
		}

		:global(.radio) {

			* {
				font-size: inherit !important;
			}

		}

	}
}

.form {
	@include form;
}

.main {
	form {
		@include form;
	}
}
