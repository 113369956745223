.root {
	display: grid;
	gap: 1rem;
	grid-auto-rows: 8rem;
	grid-template-columns: 1fr;
	justify-content: center;

	.button {
		margin-right: 0 !important;
	}

	@media all and (min-width: 680px) {
		grid-template-columns: repeat(auto-fill, 22.5rem);
		justify-content: flex-start;
	}
}
