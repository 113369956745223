.action {
	display: inline-block;
	margin-left: auto;
}

.content {
	margin-left: 1.5em !important;
}

.title {
	display: flex;
	align-items: center;
}
