.header {
	align-items: flex-start;
	display: flex;
	justify-content: space-between;

	.email {
		font-size: 1.2rem;
		font-weight: bold;
	}
}
