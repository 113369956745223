.root {
	
	&[open]:not(.no_bottom_margin) {
		summary {
			margin-bottom: 0.5em;
		}
	}

	.label {
		display: inline-block;
		pointer-events: none;
	}

	summary:focus-visible label {
		text-decoration: underline;
	}

}
