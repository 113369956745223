.contactDetailsEditors {
	max-width: 30rem;

	:global(.field) {
		margin: 0 !important;
		width: 100%;
	}
}

.contactDetailsTable {
	margin: 0 !important;
}
