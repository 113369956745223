.icon {
	animation: anim-registration-success 0.5s ease-in-out;
}

.content {
	max-width: 48rem;
	width: 100%;
}

@keyframes anim-registration-success {
	0% {
		transform: scale(0);
	}
	60% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(1);
	}
}
