.root {
	/** Stop height jump on session load (desktop, anyway) */
	min-height: 10.5rem;
}

.sessions {
	display: grid;
	gap: 0.25rem;
	grid-template-columns: repeat(auto-fill, 16rem);

	:global(.ui.button) {
		border: 0.25rem solid transparent;
		margin: 0;

		&.activeSessionButton {
			border-color: currentColor;
		}
	}
}
