.item.editing {
	:global(.ui.input) {
		&, * {
			margin: 0 !important;
		}
	}
	>:first-child {
		flex-grow: 1;
	}
}
