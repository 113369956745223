$border: 0.09rem solid rgba(34, 36, 38, 0.1);

.container {

	:global(.ui.table.sortable) {

		&, thead, tbody, tr, td, th {
			border-radius: 0 !important;
		}
		border-top: none;

		thead {
			position: sticky;
			z-index: 10;
		}

		th {

			border-top: $border;

			&:hover,
			&:focus,
			&:global(.sorted) {
				background: #f2f2f2 !important;
			}

		}

		td {
			word-break: break-word;
		}

	}

	&:global(.ui) {
		/** Remove the margin that appears on mobile devices */
		margin: 0 auto !important;
	}

	&.stackable {
		:global(.ui.table.sortable) {

			&, td {
				border: none !important;
			}

			thead {
				display: none !important;
			}

			tr {
				border: $border;
				display: flex;
				flex-direction: column;

				&:not(:first-child) {
					margin-top: 2rem;
				}
			}

			td {
				width: 100%;
			}

			.stackedLabel {
				margin-bottom: 0.5rem;
			}

		}
	}

}

.table {
	table-layout: fixed;
}

.th {
	&::after {
		float: left;
		line-height: initial;
	}
}

.thContentContainer {
	float: left;
	line-height: 100%;
}
