$toolbarPadding: 0.5rem;

.root {

	margin-top: -2.5rem;

	.header {
		align-items: flex-end;
		background: #fff;
		display: flex;
		gap: 0.5rem;
		justify-content: space-between;
		margin-top: -$toolbarPadding;
		padding: $toolbarPadding 0 calc(#{$toolbarPadding} * 2) 0;
		position: sticky;
		top: 5.5rem;
		z-index: 100;

		.heading {
			margin: 0;
		}
	}

	&.useMobileToolbar {

		.header {
			align-items: flex-start;
			flex-direction: column;

			&.noToolbar {
				top: 6rem;
			}

			>* {
				width: 100%;
				flex-grow: 1;
			}
		}

		.headerToolbar {
			align-items: unset !important;
			flex-direction: column !important;
		}

		.headerToolbarActions {
			>* {
				flex-grow: 1;
			}
		}

	}

	&:not(.useMobileToolbar):not(.hiddenTabs) {
		.header {
			top: 9.5rem;
		}
	}

	@media all and (max-width: 990px) {
		margin-top: -1.5rem;
	}

}

.tabs {
	background: #fff;
	padding-bottom: 1rem;
	position: sticky;
	top: 6rem;
	z-index: 100;
}

.viewTabs {
	margin-top: 0 !important;
}
